var SEARCH_RESULT_VAR = {
  padding: {
    "private": '--_nva-search-result--padding',
    "public": '--nva-search-result--padding',
    value: 'var(--g-spacing-0)'
  },
  titleColor: {
    "private": '--_nva-search-result--title-color',
    "public": '--nva-search-result--title-color',
    value: function value(props) {
      return "var(--sc-font-color-h3, ".concat(props.theme.fontColorH3, ")");
    }
  },
  descriptionColor: {
    "private": '--_nva-search-result--description-color',
    "public": '--nva-search-result--description-color',
    value: function value(props) {
      return "var(--sc-font-color-p, ".concat(props.theme.fontColorP, ")");
    }
  },
  borderColor: {
    "private": '--_nva-search-result--border-color',
    "public": '--nva-search-result--border-color',
    value: 'transparent'
  },
  borderWidth: {
    "private": '--_nva-search-result--border-width',
    "public": '--nva-search-result--border-width',
    value: '0'
  },
  borderRadius: {
    "private": '--_nva-search-result--border-radius',
    "public": '--nva-search-result--border-radius',
    value: 'var(--g-border-radius)'
  },
  dividerColor: {
    "private": '--_nva-search-result--divider-color',
    "public": '--nva-search-result--divider-color',
    value: 'hsl(var(--g-color-neutral-200))'
  },
  dividerContent: {
    "private": '--_nva-search-result--divider-content',
    "public": '--nva-search-result--divider-content',
    value: ''
  }
};
export default SEARCH_RESULT_VAR;