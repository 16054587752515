import { css } from 'styled-components';
import { setCssVar } from '../../../utils';
import SEARCH_RESULT_VAR from './SearchResult.variables';
import SEARCH_BLADE_VAR from '../../organisms/SearchBlade/SearchBlade.variables';
var padding = SEARCH_RESULT_VAR.padding,
  titleColor = SEARCH_RESULT_VAR.titleColor,
  descriptionColor = SEARCH_RESULT_VAR.descriptionColor,
  borderColor = SEARCH_RESULT_VAR.borderColor,
  borderRadius = SEARCH_RESULT_VAR.borderRadius,
  borderWidth = SEARCH_RESULT_VAR.borderWidth,
  dividerColor = SEARCH_RESULT_VAR.dividerColor,
  dividerContent = SEARCH_RESULT_VAR.dividerContent;
var styles = css(["&.nva-search-result{", " ", " ", " ", " padding:var(", ");border-color:var(", ");border-width:var(", ");border-radius:var(", ");border-style:solid;position:relative;&:not(:last-child)::after{", " ", " content:var(", ");display:block;width:100%;height:1px;background-color:var(", ");position:absolute;bottom:calc(var(", ") / -2);}}.nva-search-result__title{", " color:var(", ");text-wrap:pretty;}.nva-search-result__description{", " color:var(", ");overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}.nva-search-result__link{max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}"], setCssVar(padding, padding.value), setCssVar(borderColor, borderColor.value), setCssVar(borderWidth, borderWidth.value), setCssVar(borderRadius, borderRadius.value), padding["private"], borderColor["private"], borderWidth["private"], borderRadius["private"], setCssVar(dividerColor, dividerColor.value), setCssVar(dividerContent, dividerContent.value), dividerContent["private"], dividerColor["private"], SEARCH_BLADE_VAR.resultsGap["private"], function (props) {
  return setCssVar(titleColor, titleColor.value(props));
}, titleColor["private"], function (props) {
  return setCssVar(descriptionColor, descriptionColor.value(props));
}, descriptionColor["private"]);
export default styles;