var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './SearchResult.style';
import Anchor from '../../atoms/Anchor';
import { linkTargetWindow } from '../../../constants/globalConstants';
import Para from '../../atoms/Para';
import Heading from '../../atoms/Heading';
var SearchResult = function SearchResult(_ref) {
  var className = _ref.className,
    category = _ref.category,
    title = _ref.title,
    shortDescription = _ref.shortDescription,
    path = _ref.path;
  return __jsx("div", {
    className: "nva-search-result ".concat(className)
  }, __jsx(Para, {
    className: "nva-search-result__category u-overline u-m-0"
  }, category), __jsx(Heading, {
    HeadingType: "h3",
    className: "nva-search-result__title u-h3"
  }, title), shortDescription && __jsx(Para, {
    className: "nva-search-result__description u-body1 u-m-0 max-md:u-mb-3"
  }, shortDescription), __jsx(Anchor, {
    to: path,
    ctaBehavior: linkTargetWindow.newWindow,
    className: "nva-search-result__link u-m-0"
  }, window.location.origin + path));
};
export default styled(SearchResult).withConfig({
  componentId: "sc-g2wwqi-0"
})(["", ";"], styles);